import React, {useState} from "react";
import {graphql, HeadProps, PageProps} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Hero from "../../components/templates/hero/Hero";
import FeaturesZPattern from "../../components/templates/features-z-pattern/FeaturesZPattern";
import {IFeatureSection} from "../../components/templates/features-z-pattern/feature-section.interface";
import TextFormatter from "../../utils/TextFormatter";
import MobileAppLinks from "../../components/shared/mobile-app-links/MobileAppLinks";
import {IHelpSection} from "../../components/templates/help/help-section.interface";
import Help from "../../components/templates/help/Help";
import {IHeroSection} from "../../components/templates/hero/hero-section.interface";
import {SiteMetadata} from "../../types/site-metadata.type";
import {GatsbyImageCustomType} from "../../types/gatsby-image.type";
import {SiteMetadataConfigProps} from "../../types/metadata-config-props.type";
import DeviceSEO from "../../components/device/DeviceSEO";
import {PageContextType} from "../../types/page-context.type";
import Video, {IVideoSection} from '../../components/video/Video';

type DeviceMobileProps = {
  strapiDeviceMobileApp: {
    description: {
      data: {
        description: string,
      }
    },
    properties: IFeatureSection[]
    getMobileApp: {
      description: string,
      title: string,
    },
    hero: IHeroSection,
    image: GatsbyImageCustomType,
    help: IHelpSection,
    siteMetadata: SiteMetadata,
    video: IVideoSection,
  },
} & SiteMetadataConfigProps

const Mobile = ({data: {strapiDeviceMobileApp}, pageContext}: PageProps<DeviceMobileProps, PageContextType>) => {
  const [showMobileAppSection , setShowMobileAppSection] = useState(true);
  const onShowMobileAppSection = (state: boolean) => {setShowMobileAppSection(state)};

  const {help, hero, properties, getMobileApp, image, siteMetadata, video} = strapiDeviceMobileApp;
  const description = TextFormatter.concatOrphans(strapiDeviceMobileApp.description.data.description) || strapiDeviceMobileApp.description.data.description;
  const downloadNowDescription = TextFormatter.concatOrphans(getMobileApp.description) || getMobileApp.description;

  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <Hero title={hero.title} image={hero.image}/>
      <FeaturesZPattern id={'features-section'} features={properties}/>
      <Video className={'bg-white flex flex-col gap-5 items-center text-center justify-center pt-20 md:pt-28'} video={video}/>
      <section className={'bg-white flex justify-center items-center text-center px-5 md:px-24 lg:px-36 py-20 md:py-24 '}>
        <div className={'font-head text-2xl sm:text-3xl md:text-4xl xl:text-5xl max-w-[1110px]'} dangerouslySetInnerHTML={{__html: description}}/>
      </section>
      {showMobileAppSection &&
        <section className={'bg-neutrals-90 flex flex-col justify-center items-center text-center px-5 md:px-24 lg:px-36 py-20 md:py-24 '}>
          <h2 className={'font-head text-4xl xl:text-5xl mb-4 lg:mb-7'}>{getMobileApp.title}</h2>
          <p className={'text-base xl:text-lg text-neutrals-40 mb-7 lg:mb-11 max-w-[700px]'} dangerouslySetInnerHTML={{__html: downloadNowDescription}}/>
          <MobileAppLinks showSection={onShowMobileAppSection} />
        </section>
      }
      <GatsbyImage className={'sm:w-full h-[320px] sm:h-[420px]'} alt={image.alternativeText} image={image.localFile.childImageSharp.gatsbyImageData} />
      <Help helpSection={help} />
    </Layout>
  )
}

export default Mobile;

export const Head = (props: HeadProps<DeviceMobileProps>) => {
  const path = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const siteMetadata = props.data.strapiDeviceMobileApp.siteMetadata;
  return (
    <DeviceSEO siteUrl={siteUrl} path={path} siteMetadata={siteMetadata} />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiDeviceMobileApp {
      video {
        description {
          data {
            description
          }
        }
        title
        youtubeUrl
        showSection
      }
      siteMetadata {
        crumbLabel
        title
        description
      }
      help {
        description
        email
        title
        number
      }
      hero {
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 90)
              }
            }
          }
        }
      description {
        data {
          description
        }
      }
      getMobileApp {
        title
        description
      }
      properties {
        title
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
